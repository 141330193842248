import React from 'react';
import PropTypes from 'prop-types';

const PHX = props => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height={size}
      viewBox="0 0 150 150"
      fill="none"
      fillRule="evenodd"
      role="img"
      aria-describedby='title'
    >
      <title id="title">Phoenix Suns</title>
      <path
        fill="#fefefe"
        d="M111.802 18L99.465 30.273 79.62 19.884l-3.586 19.708-20.138 4.36 11.263 6.782-12.636 6.335 10.28.89-46.58 36.194 35.948-19.076-38.9 29.594 41.55-21.644-45.75 34.366 47.466-25.27L5 130.855l63.394-34.682-40.684 32.824L88 94.382l-.772 5.438 9.47-5.253 18.714 17.68 9.93-29.803 12.855-1.84-5.535-17.173 12.206-10.607-15.84-6.68 4.47-18.945-14.994 6.86z"
      />
      <path
        fill="#f9a01b"
        d="M23.8 118.978l57.644-31.54-39.09 31.54 47.43-27.233-.778 5.49 7.9-4.4 17.864 16.877 9.51-28.54 12.07-1.73-5.31-16.475 11.2-9.738-14.863-6.27 4.083-17.313-13.713 6.27-6.452-15.47-11.6 11.53-19.07-9.98-3.412 18.752-17.61 3.81 10.418 6.276-10.486 5.256 8.91.77-36.642 28.47 36.857-19.56-39.534 30.07L71.24 73.934l-44.954 33.77 49.54-26.375z"
      />
      <path
        d="M129.283 63.232C128.63 79.058 115.26 91.36 99.415 90.7c-15.84-.648-28.148-14-27.49-29.834.652-15.83 14.024-28.124 29.862-27.473 15.842.66 28.154 14.013 27.496 29.84"
        fill="#e55f1f"
      />
      <path
        d="M104.118 86.79c-15.836-.654-28.148-14.016-27.496-29.84a28.54 28.54 0 0 1 8.061-18.763c-7.367 4.915-12.37 13.15-12.76 22.68-.657 15.82 11.655 29.18 27.49 29.834 8.514.352 16.31-3.043 21.805-8.7a28.57 28.57 0 0 1-17.1 4.798"
        fill="#b85914"
      />
      <path
        d="M129.298 62.383c.014-1.028-.028-2.04-.123-3.046-1.656-1.15-3.625-2.03-5.955-2.33l-1.295-.115c-.28-1.895-.708-3.75-1.275-5.555l.895.053 5.97.665-.91-2.146-6.74-.768a33.75 33.75 0 0 0-2.654-5.312c1.466-.302 2.775-.903 3.835-1.903l-1.692-1.584c-.92.847-2.114 1.3-3.485 1.484l-.64-.877c-1.95-2.532-4.198-4.697-6.673-6.455a28.41 28.41 0 0 0-6.771-1.101c-.13-.006-.257 0-.39-.008 4.363 1.716 8.26 4.597 11.392 8.45-2.987-.288-6.43-1.288-9.837-2.286l-2.948-.852c-5.8-1.6-10.26-1.858-13.607-1.565-2.486 1.414-4.738 3.194-6.693 5.264 3.616-1.503 10.905-4.08 19.752-1.632l2.91.835c3.9 1.15 8.293 2.43 12.088 2.47 1.046 1.556 1.95 3.2 2.696 4.912l-2.22-.07c-18.434-.316-34.237 5.195-43.008 13.985a28.87 28.87 0 0 0 .599 5.116c2.472-3.55 6.136-6.75 10.863-9.41 8.922-5 20.76-7.648 33.34-7.427.43.006.867.022 1.3.04.635 1.82 1.1 3.7 1.412 5.633-8.103.34-13.355 5.4-19.385 10.763-5.412 4.842-12.018 9.663-21.682 12.563a27.84 27.84 0 0 0 2.97 3.132c9.107-3.663 15.156-9.37 20.27-13.935 5.8-5.186 10.785-10.154 18.1-10.4.187 2.02.196 4.08.01 6.16-.985 11.236-7.496 20.76-16.376 25.458 2.274-.2 4.472-.693 6.564-1.414a32.43 32.43 0 0 0 2.075-1.864c5.823-5.728 9.44-13.533 10.178-21.966.185-2.112.185-4.208.014-6.273.364.04.727.073 1.102.134 2.495.408 4.472 1.663 6.022 3.177"
        fill="#051821"
      />
    </svg>
  );
};

PHX.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

PHX.defaultProps = {
  size: '100'
};

export default PHX;
