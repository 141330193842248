import './App.css';
import styled from '@emotion/styled';

import './App.css';
import { db } from './firebase.js';
import { getDocs, getDoc, doc, onSnapshot, collection, query, orderBy, limit, where, and } from "firebase/firestore";
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import NBALogo from './components/nba-logos'


const queryParameters = new URLSearchParams(window.location.search)
let leagueId = queryParameters.get("leagueId") || 'us.nba';
let gameId;// = queryParameters.get("gameId");
const debug = queryParameters.get("debug");
const brandIt = queryParameters.get("brandIt");

let nbaRef, gameRef, eventsRef, allGamesRef;
let eventsQuery, allGamesQuery;
let game;

let snap = false;
let counter = 0;
let eventUnSubFunc;

const getLeagueGames = async (leagueId, gameId) => {
  if (leagueId) {
    nbaRef = doc(db, 'leagues', leagueId);
    //league = (await getDoc(nbaRef)).data()
    allGamesRef = collection(nbaRef, 'games');

    // this needs to be fixed - the field in firestore should be changed to date
    // going with string comparison for now
    let date = new Date();
    date.setDate(date.getDate() - 1);
    let dateStart = date.toISOString().substring(0, 10) + 'T00:00:00Z';
    date.setDate(date.getDate() + 15);
    let dateEnd = date.toISOString().substring(0, 10) + 'T00:00:00Z';
    let qca = and(where('scheduled', '>=', dateStart), where('scheduled', '<=', dateEnd))

    allGamesQuery = query(allGamesRef, qca, orderBy('scheduled', 'asc'), limit(100))
    if (gameId) {
      await getGameEvents(gameId, 20)
    }
  }
}

const getGameEvents = async (gameId, numEvents) => {
  gameRef = doc(nbaRef, 'games', gameId);
  game = (await getDoc(gameRef)).data()
  eventsRef = collection(gameRef, 'events')
  eventsQuery = query(eventsRef, orderBy('eventTimeStamp', 'desc'), limit(numEvents))
}

function processFsEventsResponse(data) {
  // console.log(`processing ${data.length} events`);
  let event;
  let eventData = [];
  data.forEach(element => {
    event = element.data();
    event.id = element.id;
    //console.log(`${event.clock}: ${JSON.stringify(event)}`);//;//`${event.id}: ${event.clock}: ${event.shortDescription}`)
    eventData.push(event)
  });
  //eventData = eventData.sort((a, b) => a.eventTimeStamp > b.eventTimeStamp ? -1 : 1)
  return eventData;
}

function processFsGamesResponse(data) {
  // console.log(`processing ${data.length} games`);
  let game;
  let gameData = [];
  data.forEach(element => {
    game = element.data();
    game.id = element.id;
    gameData.push(game)
  });
  return gameData;
}

await getLeagueGames(leagueId, gameId);

function App() {
  const [events, setEvents] = useState([])
  const [games, setGames] = useState([]);

  /** const fetchEventsOnce = async () => {
    setEvents(processFsEventsResponse(await getDocs(eventsQuery)))
  }*/

  const subToEvents = () => {
    if (eventsRef) {
      unsubFromEvents(eventsRef);
      // console.log('sub new events')
      eventUnSubFunc = onSnapshot(eventsQuery, (snapshot) => {
        setEvents(processFsEventsResponse(snapshot.docs));
      })
    }
  }

  const unsubFromEvents = () => {
    // console.log('unsub ref')
    //onSnapshot(eventsRef, () => { })
    if (eventUnSubFunc) eventUnSubFunc();
  }

  const getGameDay = (d) => {
    if (typeof d == 'string') d = new Date(d);
    //game day: UTC 4pm to UTC 4pm UTC midnight - 4pm: game day = date - 1 day --> game day = UTC date - 16h
    return (new Date(d.setTime(d.getTime() - (16 * 60 * 60 * 1000)))).toISOString().split('T')[0];
  }

  const fetchGamesOnce = async () => {
    let allGames = processFsGamesResponse(await getDocs(allGamesQuery))
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1)
    //allGames = allGames.filter(game => { return (new Date(game.scheduled) >= new Date() && new Date(game.scheduled) <= tomorrow) })
    if (!debug) allGames = allGames.filter(game => { return (getGameDay(game.scheduled) == getGameDay(new Date())) })
    allGames.sort((a, b) => {
      if (new Date(a.scheduled) == new Date(b.scheduled)) return (a.homeTeam.alias > b.homeTeam.alias ? -1 : 1)
      return new Date(a.scheduled) < new Date(b.scheduled) ? -1 : 1
    });
    setGames(allGames)
  }

  useEffect(() => {
    if (!snap) {
      if (eventsQuery) {
        //onSnapshot(eventsQuery, (snapshot) => {
        //  setEvents(processFsEventsResponse(snapshot.docs));
        //  })
      }
      // likely fine to not subscribe
      fetchGamesOnce();
      /**if (allGamesQuery) {
        onSnapshot(allGamesQuery, (snapshot) => {
          setGames(processFsGamesResponse(snapshot.docs));
      
        })
      }*/
      snap = true;
    }


  }, [])

  const switchLeague = (event) => {
    snap = false;
    leagueId = event.target.value;
    getLeagueGames(leagueId);
    if (eventUnSubFunc) eventUnSubFunc()
    // pulling the games once
    fetchGamesOnce();
    setEvents([]);
    game = {}
  }
  const loadGameEvents = async (gameId) => {
    // console.log(`switched to ${gameId}`);
    let curGame = games.filter(g => g.id === gameId)[0];
    document.title = (curGame && `${curGame.homeTeam.alias} : ${curGame.awayTeam.alias}`) || 'Basketball'

    if (counter++ < 100) {
      await getGameEvents(gameId, 20)
      //fetchEventsOnce();
      subToEvents(eventsQuery);
    } else {
      console.log('clear only')
    }
  }

  const selectChange = async (selectedOption) => {
    loadGameEvents(selectedOption.value)
    // fetchEvents()
  }
  const getShortDateTime = (d) => {
    if (typeof d == 'string') d = new Date(d);
    let locDate = `${d.getMonth() + 1}/${d.getDate()}`
    let locTime = `${d.getHours() % 12 == 0 ? '12' : d.getHours() % 12}:${d.getMinutes() < 10 ? '0' : ''}${d.getMinutes()} ${d.getHours() >= 12 ? 'pm' : 'am'}`
    return `${locDate} @ ${locTime}`;
  }



  let selectOptions = [];
  games.filter(game => { return new Date(game.scheduled) >= new Date('2023-10-17') }).forEach(game => {
    selectOptions.push(
      {
        value: game.id,
        label: `${getShortDateTime(game.scheduled)} - ${game.homeTeam.market} ${game.homeTeam.name} vs. ${game.awayTeam.market} ${game.awayTeam.name}
        ${debug ? '(' + game.id + ')' : ''}`,
      });
  });

  const customStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#212529" : "#fff",
      backgroundColor: state.isSelected ? "#a0a0a0" : "#212529",
      fontSize: "10pt",

    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "#212529",
      padding: "5px",
      margin: "10px",
      border: "none",
      boxShadow: "none",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#fff" }),
  };

  let gameSelector =
    <Select key={`my_unique_select_key__${games.length}`} label='Hello' onChange={selectChange} options={selectOptions} menuPortalTarget={document.body} styles={customStyles} />

  let gameList =
    <GameList >
      {games.map(g => {
        return (
          <a onClick={() => loadGameEvents(g.id)} style={{ cursor: 'pointer' }}><GameBox>
            <TeamVs>
              <TeamGame color={g.homeTeam.primaryColor}>{g.homeTeam.alias}</TeamGame>
              <TeamGame color={g.awayTeam.primaryColor}>{g.awayTeam.alias}</TeamGame>
            </TeamVs>
            <GameDate>{getShortDateTime(g.scheduled)}</GameDate>

          </GameBox ></a>
        )
      })}
    </GameList >;
  return (
    <>
      <Score><div>A</div><div>B</div></Score>
      <Stage>
        <a href="https://poweredbytally.com" target="tally"><Logo src="https://s3.us-west-1.amazonaws.com/megaphone-assets.playtally.com/aifeed/Tally_Logo_Horizontal_White%402x-16-9.png" /></a>

        <TickerContainer>
          <LinkPanel>
            <Nav>
              <NavButton value="us.nba" onClick={switchLeague} isActive={(leagueId === 'us.nba')}>NBA</NavButton>
              {debug && <NavButton value="us.nba-sim" onClick={switchLeague} isActive={(leagueId === 'us.nba-sim')}>NBA Simulation</NavButton>}
            </Nav>
            <div>
              <center>
                <div><Header1>Live NBA Game Commentary</Header1>
                  <Header2>Powered by AI</Header2>
                </div>
                <small><b>Real time stats and commentary for today's games. Pick a game from the schedule to get up to the second news.</b></small><br />
                ({leagueId && leagueId === 'us.nba-sim' ? <small>Using SportRadar simulation data for demo. Start times are approximate. Feeds reset daily at 8pm ET.</small> : <small>Start times are approximate.</small>})
              </center>
            </div>
            {gameList}
          </LinkPanel>
          {game && game.homeTeam && events &&
            <GameHeader>
              <div>
                <b>{game.homeTeam.market} {game.homeTeam.name} vs. {game.awayTeam.market} {game.awayTeam.name}</b>&nbsp;
                {debug && (<>(<a href={`https://www.google.com/search?q=${encodeURIComponent(game.homeTeam.market + ' ' + game.homeTeam.name + ' vs. ' + game.awayTeam.market + ' ' + game.awayTeam.name)}`} target={`${game.homeTeam.alias}-${game.awayTeam.alias}`} style={{ color: '#ffffff' }}>Google</a>)</>)}
              </div>
              <div>{game.homeTeam.venue} ({game.homeTeam.venueCity})</div>
              <div>{new Date(game.scheduled).toLocaleDateString()} - {new Date(game.scheduled).toLocaleTimeString()}</div>
            </GameHeader>
          }
          {
            game && events && events.map(event => {
              let periodLabel = 'Period';
              if (event.periodType && event.periodType.length >= 2) {
                periodLabel = event.periodType.charAt(0).toUpperCase() + event.periodType.slice(1);
              }
              return (
                <>
                  <VerticalSpace />
                  <TickerItem>
                    <ScoreBox homeColor={game.homeTeam.primaryColor} awayColor={game.awayTeam.primaryColor}>
                      <Clock>{event.clock} ({event.period}{event.period === 1 ? 'st' : event.period === 2 ? 'nd' : event.period === 3 ? 'rd' : 'th'} {periodLabel})</Clock>
                      <Score>
                        {brandIt && (<NBALogo team={game.homeTeam.alias} size={40} />)}&nbsp;
                        <TeamScore color={game.homeTeam.primaryColor}>{game.homeTeam.alias}&nbsp;&nbsp;{event.pointsHome}</TeamScore>
                        <TeamScore color={game.awayTeam.primaryColor}>{event.pointsAway}&nbsp;&nbsp;{game.awayTeam.alias}</TeamScore>&nbsp;
                        {brandIt && (<NBALogo team={game.awayTeam.alias} size={40} />)}
                      </Score>
                    </ScoreBox>
                    <TickerText>{event.aiCommmentary}</TickerText>

                  </TickerItem>
                  {debug ?
                    (<DebugContainer>
                      <small>
                        {event.id} @
                        {new Date(event.eventTimeStamp * 1000).toLocaleDateString()} @ {new Date(event.eventTimeStamp * 1000).toLocaleTimeString()}
                        ({new Date(event.recordTimeStamp * 1000).toLocaleTimeString()})</small>
                    </DebugContainer>)
                    :
                    (<VerticalSpace />)}
                </>
              )
            })
          }
          {events.length > 0 ? <GameHeader><center><b>Displaying the 20 most recent AI Commentaries</b></center></GameHeader> : <></>}
        </TickerContainer>
      </Stage >
    </>
  );
}

export default App;

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
}

const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`
};

const breakPoint = device.tablet;

const Stage = styled.div`
        background-image: url("https://assets.playtally.com/aifeed/nba-background.jpeg") ;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: left top;
        min-height: 1335px;
        `;

const Logo = styled.img`
        position: absolute;
        top: 0px;
        left: 0px;
        position: fixed;
        width: 150px;
        min-width:100px;
        max-width: 150px;
        @media ${breakPoint} {
          min-width: 10px; 
          max-width: 80px;
          right: 0px;
          margin: 0 auto;
        }
        margin: 0;
        padding: 0;
        `;

const Header1 = styled.h1`
  font-weight: 900;
  font-size: 20pt;
  margin: 10px;
`

const Header2 = styled.h2`
  font-weight: 700;
  font-size: 18pt;
  font-style: italic;
  margin: 10px;
`
// main container for the content;
const TickerContainer = styled.div`
        width: 50%;
        min-width: 40ch;        
        margin: auto;
        @media${breakPoint}{
          margin-top:30px;
        }
        `;

const Nav = styled.ul`
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        `;
const NavButton = styled.button`
        display: block;
        padding: 8px;
        background-color: ${(props) => (props.isActive ? "#000000" : "#dddddd")};
        float: left;
        border-color: #ffffff;
        border-style: solid;
        opacity: 1;
        font-weight: 600;
        
        color: ${(props) => (props.isActive ? "#ffffff" : "#000000")};
        &:hover {
          background-color: #000000; 
          color: #dddddd;
        };
        `;
const LinkPanel = styled.div`
        background: #ffffff;
        opacity: 0.8;
        padding:10px;
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 5px;
        max-width: 80ch;
        margin-bottom: 10px;
        `;

const VerticalSpace = styled.div`
        height: 20px;
        `;

// the stuff for the game list
const GameList = styled.div`
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        max-width: 100%;
      
`
const GameBox = styled.div`
        background: black;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 2px;
        padding: 2px;
        `
const TeamVs = styled.div`
        color:  blue;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        `

const TeamGame = styled.div`
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: ${(props) => (props.color ? props.color : "#00ff00")};
        justify-content: center;
        padding: 5px 10px 5px 10px;
        font-weight: 900;
        font-size: 80%;
        width: 5ch;
        color: #ffffff;
`
const GameDate = styled.div`
        color:  black;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 80%;
        font-weight: 900;
        width: 100%
        `
// the header of the chosen game
const GameHeader = styled.div`
        position: relative;
        display: flex;
        flex-direction: column;
        background: rgb(0, 0, 0);
        color: #ffffff;
        padding: 5px;
        max-width: 80ch;
        border-radius: 0px;
        `;

// https://cssgradient.io/

const TickerItem = styled.div`
        position: relative;
        display: flex;
        flex-direction: column;
        background: rgb(255,2550, 255);
        padding: 5px;
        max-width: 80ch;
        border-radius: 5px;
        border-style: solid;
        border-color: #000000
        `;

const TickerText = styled.div`
        color: #000000;
        text-align: left;
        margin: 10px;
        font-weight: 500;
        font-size: 100%;
 
        font-family: Helvetica, Arial, sans-serif;
        `;

const ScoreBox = styled.div`
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #cccccc;
        border-left: 4px solid ${(props) => (props.homeColor ? props.homeColor : "#cccccc")};
        border-right: 4px solid ${(props) => (props.awayColor ? props.awayColor : "#cccccc")};
        padding: 5px 5px 0px 5px;
        min-width: 8ch;
        text-align: center;

        `

const Score = styled.div`
        color:  blue;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        `
const TeamScore = styled.div`
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: ${(props) => (props.color ? props.color : "#00ff00")};
        justify-content: center;
        padding: 5px 10px 5px 10px;
        font-weight: 900;
        font-size: 80%;
        color: #ffffff;
`

const Clock = styled.div`
        color: #090909;
        justify-content: center;
        font-weight: bold;
        font-size: 60%;
        `
const DebugContainer = styled.div`
        position: relative;
        display: flex;
        justify-content: center;
        background-color: #ffffff;
        `;
