import React from 'react';
import PropTypes from 'prop-types';

const DEN = props => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height={size}
      viewBox="0 0 150 150"
      fill="none"
      fillRule="evenodd"
      role="img"
      aria-describedby='title'
    >
      <title id="title">Denver Nuggets</title>
      <path
        d="M75.513 27c12.923 0 25.1 5.048 34.27 14.23C118.945 50.4 124 62.577 124 75.49c0 26.756-21.74 48.51-48.487 48.51-12.954 0-25.136-5.038-34.3-14.223C32.06 100.612 27 88.434 27 75.49c0-12.912 5.06-25.1 14.203-34.26C50.377 32.048 62.56 27 75.513 27"
        stroke="#fff"
        fill="#ffc627"
      />
      <path
        d="M75.52 120.12c24.595 0 44.6-20.007 44.6-44.633 0-24.58-20.006-44.607-44.6-44.607-24.624 0-44.64 20.026-44.64 44.607 0 24.626 20.016 44.633 44.64 44.633"
        fill="#8b2332"
      />
      <path
        d="M34.275 75.488c0-22.72 18.5-41.213 41.24-41.213s41.2 18.493 41.2 41.213c0 22.738-18.475 41.236-41.2 41.236s-41.24-18.498-41.24-41.236"
        fill="#0d2240"
      />
      <path
        d="M108.03 58.2l.92-.515-2.968-4.604-.83.6c-5.05-6.37-14-13.47-22.54-14.546 6.422 2.932 13.647 10.42 18.06 16.194l-4.635 3.37.557.856-20.938 13.603-20.938-13.603.56-.856-4.662-3.37c4.44-5.773 11.648-13.262 18.078-16.194-8.52 1.074-17.5 8.16-22.498 14.523l-.87-.626-2.97 4.594.926.536-.024.038c-3.687 7.242-6.396 18.437-3.757 26.627-.07-7.076 3.733-16.8 7.148-23.288l5 2.818.517-.8L71.6 75.78 45.963 92.444l-.04-.05-3.438.927.037.085c-.168.64.147 1.823.878 2.953.73 1.162 1.65 1.927 2.34 2.054l.06.095 2.283-2.76-.02-.05 51.085-32.14.5.8 5.015-2.818c3.4 6.48 7.217 16.212 7.173 23.288 2.637-8.2-.105-19.385-3.807-26.627m-2.634 34.2l-.04.05-23.6-15.33-4.133 2.447 25.627 16.136-.028.05 2.277 2.76.07-.095c.7-.127 1.6-.892 2.34-2.054.7-1.13 1.045-2.315.877-2.953l.042-.085-3.433-.927zm-25.692-49.14L77.93 45.58l-2.456-5.862v27.074l8.248-10.557 8.298 2.14z"
        fill="#ffc627"
      />
      <path
        d="M79.703 49.6v-6.347l-1.774 2.32zm-4.23-9.892L60.505 57.964l6.606-1.68 8.362 10.5z"
        fill="#fff"
      />
      <path
        d="M66.688 107.998a12.84 12.84 0 0 1 7.726-3.553v-4.058h-11.18c.266 2.955 1.507 5.603 3.455 7.6m7.725 3.763v-4.8c-2.17.265-4.18 1.177-5.832 2.675 1.72 1.146 3.7 1.896 5.832 2.125"
        fill="#ffc627"
      />
      <path
        d="M76.897 86.896v4.786c2.166-.262 4.2-1.16 5.808-2.657-1.695-1.155-3.664-1.9-5.808-2.13m7.716 3.765c-2.122 2-4.812 3.28-7.716 3.536v3.7h11.16c-.34-2.797-1.594-5.32-3.445-7.227m-10.198 1.02v-4.785a12.52 12.52 0 0 0-5.804 2.13c1.624 1.495 3.633 2.393 5.804 2.655"
        fill="#fff"
      />
      <path
        d="M76.897 100.388v4.058c2.904.273 5.614 1.516 7.716 3.553 1.953-2.008 3.24-4.657 3.47-7.61H76.897z"
        fill="#ffc627"
      />
      <path
        d="M63.258 97.89h11.156v-3.69c-2.91-.257-5.59-1.527-7.727-3.537a12.49 12.49 0 0 0-3.429 7.228"
        fill="#fff"
      />
      <path
        d="M76.897 111.76c2.144-.23 4.113-.98 5.808-2.125-1.618-1.498-3.642-2.4-5.808-2.675v4.8z"
        fill="#ffc627"
      />
    </svg>
  );
};

DEN.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

DEN.defaultProps = {
  size: '100'
};

export default DEN;
