import React from 'react';
import PropTypes from 'prop-types';

const NOP = (props) => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height={size}
      viewBox="0 0 150 150"
      fill="none"
      fillRule="evenodd"
      role="img"
      aria-describedby='title'
    >
      <title id="title">New Orleans Pelicans</title>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(0 45.11)">
          <mask id="S_NOP" fill="#fff">
            <path id="A_NOP" d="M.001 59.362h149.773V.078H.001z" />
          </mask>
          <path
            d="M147.673 17.39C128.028 5.957 99.478.302 86.18.078c-3.226 0-4.717 1.518-5.403 2.797a5.36 5.36 0 0 0-.556 1.612c-.734-.46-1.338-.763-1.374-.783l-1.98-.998.174 1.144a47.75 47.75 0 0 1-1.552-.884l-.583-.352-.585.347s-.775.457-1.58.9l.178-1.153-1.985 1.005c-.04.02-.642.324-1.376.783-.096-.557-.284-1.098-.558-1.614-.68-1.276-2.172-2.795-5.6-2.795-13.112.224-41.66 5.88-61.327 17.327l-2.08 1.283.348.888c.078.196 1.758 4.36 5.83 6.33l.252.41c.105.167 2.25 3.587 5.778 5.153l.245.395c.117.187 2.577 4.078 7.355 5.722l.535.578c.15.162 3.265 3.468 8.263 4.694l-.046.034 1.333.888c.153.103 3.34 2.194 8.25 3.215l-.606.54 1.797.655c.44.162 10.47 3.756 19.512.995 2.897 6.128 9.214 10.178 16.046 10.178s13.144-4.05 16.04-10.178c9.036 2.76 19.073-.833 19.512-.995l1.795-.655-.604-.537c4.907-1.023 8.094-3.114 8.247-3.217l1.333-.888-.043-.034c4.996-1.226 8.1-4.532 8.26-4.694l.53-.575c4.783-1.644 7.243-5.537 7.358-5.724l.245-.393c3.53-1.566 5.677-4.99 5.78-5.155l.254-.41c4.072-1.968 5.75-6.133 5.828-6.33l.348-.888-2.1-1.297z"
            fill="#fefefe"
            mask="url(#S_NOP)"
          />
        </g>
        <path
          d="M148.362 64.27l-1.267-.783C127.616 52.148 99.334 46.55 86.35 46.33h-.17c-2.135 0-3.615.737-4.392 2.2-.656 1.235-.58 2.473-.26 3.52-.224-.256-.55-.527-.903-.788-1.054-.795-2.293-1.42-2.293-1.42s.162 1.023-.215.897c-.176-.055-.393-.148-.633-.258-1.054-.505-2.58-1.418-2.58-1.418s-1.55.913-2.602 1.418c-.24.1-.46.203-.636.258-.377.126-.213-.897-.213-.897s-1.24.626-2.296 1.42c-.354.26-.68.532-.903.788.32-1.048.398-2.285-.26-3.52-.777-1.452-2.257-2.2-4.392-2.2h-.17c-12.984.22-41.267 5.817-60.744 17.156l-1.27.783s1.91 4.863 6.633 6.247l-.642.31s2.668 4.322 6.816 5.22l-.793.33s2.924 4.72 8.592 5.717l-.837.404s4.045 4.365 10.247 4.756l-.9.687s4.11 2.735 10.172 3.422l-.974.865s10.748 3.925 19.75.653c2.483 6.235 8.585 10.45 15.4 10.45h.005c6.825 0 12.927-4.215 15.4-10.45 9 3.272 19.75-.653 19.75-.653l-.974-.865c6.06-.687 10.172-3.422 10.172-3.422l-.9-.687c6.203-.4 10.245-4.756 10.245-4.756l-.835-.404c5.668-.998 8.6-5.717 8.6-5.717l-.79-.33c4.147-.897 6.816-5.22 6.816-5.22l-.642-.31c4.72-1.384 6.633-6.247 6.633-6.247"
          fill="#b49759"
        />
        <g fill="#b49759">
          <path d="M54.276 58.205c-14.592 1.895-28.86 6.97-28.86 6.97 2.43 4.315 9.02 2.505 9.02 2.505 12.362-3.496 17.88-4.68 19.65-5.023a25.24 25.24 0 0 1 .192-4.452" />{' '}
          <path d="M54.385 65.3c-2.437.47-12.653 2.564-22.957 6.42 0 0 3.25 3.694 9.694 1.868 5.42-1.534 12.372-3.75 14.445-4.413a17 17 0 0 1-1.182-3.875" />{' '}
          <path d="M56.65 71.217c-2.202.7-13.21 4.272-17.86 6.053 0 0 2.666 3.57 10.364.546l9.62-3.895c-.775-.8-1.493-1.7-2.124-2.703" />{' '}
          <path d="M60.472 75.474l-13.2 5.938s5.2 2.553 11.74-1.484l4.067-2.664c-.885-.516-1.765-1.1-2.61-1.8" />{' '}
          <path d="M65.06 78.3c-1.308.815-6.726 4.22-9.372 6.17 0 0 4.5 1.854 9.097-2.48l2.77-2.664a28.99 28.99 0 0 1-2.494-1.027" />{' '}
        </g>
        <g fill="#b49759">
          <use xlinkHref="#G_NOP" />
          <path d="M95.392 65.3c2.435.47 12.65 2.564 22.957 6.42 0 0-3.25 3.694-9.694 1.868-5.42-1.534-12.372-3.75-14.445-4.413.517-1.167.92-2.457 1.182-3.875" />{' '}
          <path d="M93.128 71.217c2.2.7 13.21 4.272 17.857 6.053 0 0-2.664 3.57-10.362.546l-9.62-3.895c.775-.8 1.493-1.7 2.124-2.703" />{' '}
          <path d="M89.304 75.474l13.2 5.938s-5.2 2.553-11.743-1.484l-4.065-2.664c.885-.516 1.763-1.1 2.61-1.8" />{' '}
          <path d="M84.717 78.3c1.308.815 6.726 4.22 9.372 6.17 0 0-4.493 1.854-9.102-2.48l-2.764-2.664a28.99 28.99 0 0 0 2.494-1.027" />{' '}
        </g>
        <g fill="#002a5b">
          <path d="M82.227 97.304c-.56-.438-1.356-1.215-1.498-2.174l-.032-.256a14.74 14.74 0 0 0 6.848-5.854c-.6 3.418-2.574 6.37-5.318 8.283zM62.23 89c1.62 2.628 4.013 4.676 6.852 5.865l-.032.258c-.144.96-.937 1.735-1.498 2.17a12.86 12.86 0 0 1-5.323-8.295zm2.963-10.667c-.574 3.17.743 6.16 1.95 8.893.84 1.904 1.644 3.73 1.902 5.58-3.32-1.655-5.842-4.685-6.78-8.482.437-2.265 1.47-4.32 2.93-5.99zM78.798 93.57c-.956.306-1.955.507-2.98.582V73.96c2.062.148 4 .78 5.67 1.788 2.552 3.68 1.113 6.966-.553 10.74-1 2.265-2.023 4.594-2.135 7.082zm-2.98 2.438a15.17 15.17 0 0 0 3.093-.507c.208 1.194.947 2.144 1.632 2.795a12.73 12.73 0 0 1-4.726 1.279v-3.566zM70.87 95.5c.995.272 2.028.443 3.09.507v3.566a12.73 12.73 0 0 1-4.724-1.279c.688-.65 1.427-1.6 1.635-2.795zm-2.577-19.754c1.678-1.007 3.608-1.64 5.666-1.788V94.15c-1.027-.07-2.023-.265-2.977-.56-.105-2.498-1.134-4.83-2.138-7.105-1.67-3.774-3.105-7.06-.55-10.74zm19.224 8.598c-.928 3.717-3.478 6.75-6.777 8.427.265-1.836 1.063-3.648 1.895-5.537 1.207-2.735 2.524-5.724 1.948-8.893 1.463 1.676 2.497 3.733 2.933 6.002zM74.888 71.753c-8.286 0-15.028 6.735-15.028 15.012s6.742 15.01 15.028 15.01 15.03-6.733 15.03-15.01-6.742-15.012-15.03-15.012z" />{' '}
          <path d="M79.353 52.27s-.112.473-.4.473-2.092-.865-2.96-1.336l-1.07-.605-1.143.605c-.867.47-2.668 1.336-2.96 1.336s-.4-.473-.4-.473-4.735 2.854-4.735 10.74c0 5.87 3.06 8.534 3.06 8.534l.416 1.66H80.6l.416-1.66s3.06-2.664 3.06-8.534c0-7.886-4.735-10.74-4.735-10.74" />{' '}
        </g>
        <g fill="#e21836">
          <path d="M72.882 82.15l-2.577-12.7c-.514-2.285.862-1.372 1.603.057l.974 12.642z" />{' '}
          <path d="M76.895 82.15l2.577-12.7c.514-2.285-.862-1.372-1.603.057l-.974 12.642z" />{' '}
          <path d="M71.202 63.257a.39.39 0 0 1-.272-.486c.023-.08.073-.144.135-.196a3.01 3.01 0 0 0-.498-.452s-.345 1.614 1.097 1.44c0 0-.05-.144-.158-.35a.4.4 0 0 1-.304.043" />{' '}
          <path d="M79.208 62.123a3.01 3.01 0 0 0-.498.452.41.41 0 0 1 .139.199c.057.2-.07.427-.277.484-.107.027-.215.01-.302-.04-.107.2-.158.347-.158.347 1.443.174 1.095-1.44 1.095-1.44" />{' '}
          <path d="M75.564 84.22c.457-8.617 1.145-14.722 1.145-15.484 0-.27-.03-.62-.144-1.005l-.5 1.64-.155-2.897c-.252-.324-.594-.648-1.03-.96h-.027c-.432.31-.736.635-1 .96l-.15 2.897-.505-1.642c-.112.386-.144.737-.144 1.007 0 .763.686 6.868 1.143 15.484s-.6 10.222-.6 10.222c.62.895 1.3 2.49 1.3 2.49s.67-1.594 1.3-2.49c0 0-1.07-1.603-.613-10.222" />{' '}
        </g>
        <path
          d="M146.313 64.83c-19.055-11.085-47.05-16.724-59.985-16.943-1.56-.025-2.62.354-3.164 1.368-.9 1.7.37 3.575.912 4.388 1.173 1.744 2.725 4.475 2.725 7.758 0 6.368-5.156 11.078-11.505 11.078l-.407.002c-.137 0-.272-.002-.41-.002-6.347 0-11.505-4.7-11.503-11.078 0-3.283 1.552-6.014 2.723-7.758.542-.813 1.815-2.7.912-4.388-.542-1.014-1.603-1.393-3.164-1.368-12.936.22-40.928 5.86-59.985 16.943.016.048 3.055 5.24 9.12 4.797l-2.796 1.4c.016.057 2.783 4.603 9.463 3.945l-3.324 1.614c.014.062 3.205 4.53 10.243 4.413l-2.595 1.338c.005.034 4.65 4.523 12.16 3.288l-2.6 1.895c.002.01 5.517 3.02 12.006 2.085l-2.508 1.918c-.016-.084 9.937 2.722 17.015-.4 6.09-2.68 9.475-9.247 10.247-10.9a30.87 30.87 0 0 0 3.052.511c-.002.087 1.3.2 1.94.17.65.027 1.943-.084 1.94-.17a30.84 30.84 0 0 0 3.05-.511c.77 1.653 4.157 8.22 10.247 10.9 7.08 3.112 17.03.306 17.015.4l-2.508-1.918c6.49.936 12.003-2.073 12.003-2.085-.965-.676-1.786-1.304-2.597-1.895 7.51 1.235 12.157-3.253 12.16-3.288.007.002-.974-.518-2.595-1.338 7.037.116 10.23-4.352 10.243-4.413l-3.322-1.614c6.678.658 9.445-3.888 9.46-3.945l-2.794-1.4c6.066.443 9.104-4.75 9.12-4.797"
          fill="#002a5b"
        />
        <path
          d="M69.405 76.878s-17.03-5.692-9.63-23.802c0 0-18.003.747-42.682 10.7 0 0-1.73.72-2.74 1.08s-2.634 1.005-4.96-.1c0 0 22.57-11.783 51.57-14.516 3.823-.36 4.328.217 3.318 1.658-.828 1.18-11.183 14.338 5.124 25"
          fill="#fefefe"
        />
        <g fill="#b49759">
          <path d="M54.276 58.205c-14.592 1.895-28.86 6.97-28.86 6.97 2.43 4.315 9.02 2.505 9.02 2.505 12.362-3.496 17.88-4.68 19.65-5.023a25.24 25.24 0 0 1 .192-4.452" />{' '}
          <path d="M54.385 65.3c-2.437.47-12.653 2.564-22.957 6.42 0 0 3.25 3.694 9.694 1.868 5.42-1.534 12.372-3.75 14.445-4.413a17 17 0 0 1-1.182-3.875" />{' '}
          <path d="M56.65 71.217c-2.202.7-13.21 4.272-17.86 6.053 0 0 2.666 3.57 10.364.546l9.62-3.895c-.775-.8-1.493-1.7-2.124-2.703" />{' '}
          <path d="M60.472 75.474l-13.2 5.938s5.2 2.553 11.74-1.484l4.067-2.664c-.885-.516-1.765-1.1-2.61-1.8" />{' '}
          <path d="M65.06 78.3c-1.308.815-6.726 4.22-9.372 6.17 0 0 4.5 1.854 9.097-2.48l2.77-2.664a28.99 28.99 0 0 1-2.494-1.027" />{' '}
        </g>
        <path
          d="M80.372 76.878s17.03-5.692 9.63-23.802c0 0 18.003.747 42.68 10.7 0 0 1.73.72 2.74 1.08s2.634 1.005 4.96-.1c0 0-22.57-11.783-51.57-14.516-3.825-.36-4.328.217-3.32 1.658.83 1.18 11.185 14.338-5.12 25"
          fill="#fefefe"
        />
        <g fill="#b49759">
          <use xlinkHref="#G_NOP" />
          <path d="M95.392 65.3c2.435.47 12.65 2.564 22.957 6.42 0 0-3.25 3.694-9.694 1.868-5.42-1.534-12.372-3.75-14.445-4.413.517-1.167.92-2.457 1.182-3.875" />{' '}
          <path d="M93.128 71.217c2.2.7 13.21 4.272 17.857 6.053 0 0-2.664 3.57-10.362.546l-9.62-3.895c.775-.8 1.493-1.7 2.124-2.703" />{' '}
          <path d="M89.304 75.474l13.2 5.938s-5.2 2.553-11.743-1.484l-4.065-2.664c.885-.516 1.763-1.1 2.61-1.8" />{' '}
          <path d="M84.717 78.3c1.308.815 6.726 4.22 9.372 6.17 0 0-4.493 1.854-9.102-2.48l-2.764-2.664a28.99 28.99 0 0 0 2.494-1.027" />{' '}
        </g>
        <path
          d="M88.832 86.764a13.94 13.94 0 1 1-27.889 0 13.94 13.94 0 1 1 27.889 0"
          fill="#fefefe"
        />
        <path
          d="M85.947 89.645c0 6.105-4.952 11.048-11.06 11.048S63.83 95.75 63.83 89.645c0-6.098 4.95-11.044 11.06-11.044s11.06 4.945 11.06 11.044"
          fill="#b49759"
        />
        <g fill="#002a5b">
          <path d="M82.227 97.304c-.56-.438-1.356-1.215-1.498-2.174l-.032-.256a14.74 14.74 0 0 0 6.848-5.854c-.6 3.418-2.574 6.37-5.318 8.283zM62.23 89c1.62 2.628 4.013 4.676 6.852 5.865l-.032.258c-.144.96-.937 1.735-1.498 2.17a12.86 12.86 0 0 1-5.323-8.295zm2.963-10.667c-.574 3.17.743 6.16 1.95 8.893.84 1.904 1.644 3.73 1.902 5.58-3.32-1.655-5.842-4.685-6.78-8.482.437-2.265 1.47-4.32 2.93-5.99zM78.798 93.57c-.956.306-1.955.507-2.98.582V73.96c2.062.148 4 .78 5.67 1.788 2.552 3.68 1.113 6.966-.553 10.74-1 2.265-2.023 4.594-2.135 7.082zm-2.98 2.438a15.17 15.17 0 0 0 3.093-.507c.208 1.194.947 2.144 1.632 2.795a12.73 12.73 0 0 1-4.726 1.279v-3.566zM70.87 95.5c.995.272 2.028.443 3.09.507v3.566a12.73 12.73 0 0 1-4.724-1.279c.688-.65 1.427-1.6 1.635-2.795zm-2.577-19.754c1.678-1.007 3.608-1.64 5.666-1.788V94.15c-1.027-.07-2.023-.265-2.977-.56-.105-2.498-1.134-4.83-2.138-7.105-1.67-3.774-3.105-7.06-.55-10.74zm19.224 8.598c-.928 3.717-3.478 6.75-6.777 8.427.265-1.836 1.063-3.648 1.895-5.537 1.207-2.735 2.524-5.724 1.948-8.893 1.463 1.676 2.497 3.733 2.933 6.002zM74.888 71.753c-8.286 0-15.028 6.735-15.028 15.012s6.742 15.01 15.028 15.01 15.03-6.733 15.03-15.01-6.742-15.012-15.03-15.012z" />{' '}
          <path d="M79.353 52.27s-.112.473-.4.473-2.092-.865-2.96-1.336l-1.07-.605-1.143.605c-.867.47-2.668 1.336-2.96 1.336s-.4-.473-.4-.473-4.735 2.854-4.735 10.74c0 5.87 3.06 8.534 3.06 8.534l.416 1.66H80.6l.416-1.66s3.06-2.664 3.06-8.534c0-7.886-4.735-10.74-4.735-10.74" />{' '}
        </g>
        <path
          d="M81.61 61.017c0-2.436-.992-4.422-2.062-5.605 0 0-.037.7-1.203-.08a6.08 6.08 0 0 0-3.462-1.096c-1.278 0-2.465.406-3.473 1.1-1.125.765-1.18.075-1.18.075-1.068 1.183-2.062 3.17-2.062 5.605 0 2.025.496 3.02.67 3.306 1.004 2.578 3.33 4.386 6.045 4.386 2.68 0 4.984-1.77 6.01-4.306.06-.082.718-1.053.718-3.386"
          fill="#fefefe"
        />
        <path
          d="M77.417 66.522c-.007-.014-.018-.027-.025-.04 0 0-.665-1.247.43-2.1 0 0 2.478.516 2.538-2.765 0 0-.06-.35.288-1.037 0 0-.636-.578-2.02.46-1.386 1.032-2.133 3.97-2.883 3.28-.74-.685-.47-4.51-.855-4.655h-.014c-.382.148-.1 3.97-.853 4.655s-1.5-2.25-2.883-3.28c-1.386-1.04-2.017-.46-2.017-.46.345.687.286 1.037.286 1.037.06 3.28 2.538 2.765 2.538 2.765 1.097.863.434 2.114.434 2.114-.007.014-.016.027-.023.04-2.96-3.174-4.678-2.742-3.83.813.835 3.493 4.035 18.484 4.543 21.962-.07 3.128-.432 4.244-.526 4.484l-.325.646.357.7c.565.82.953 1.735 1.093 2.085l1.22 3.096 1.21-3.09a11.68 11.68 0 0 1 1.095-2.091l.373-.676-.336-.678c-.096-.235-.448-1.31-.528-4.327.42-3.117 3.692-18.57 4.54-22.122s-.87-3.986-3.83-.815"
          fill="#002a5b"
        />
        <g fill="#e21836">
          <path d="M72.882 82.15l-2.577-12.7c-.514-2.285.862-1.372 1.603.057l.974 12.642z" />{' '}
          <path d="M76.895 82.15l2.577-12.7c.514-2.285-.862-1.372-1.603.057l-.974 12.642z" />{' '}
          <path d="M71.202 63.257a.39.39 0 0 1-.272-.486c.023-.08.073-.144.135-.196a3.01 3.01 0 0 0-.498-.452s-.345 1.614 1.097 1.44c0 0-.05-.144-.158-.35a.4.4 0 0 1-.304.043" />{' '}
          <path d="M79.208 62.123a3.01 3.01 0 0 0-.498.452.41.41 0 0 1 .139.199c.057.2-.07.427-.277.484-.107.027-.215.01-.302-.04-.107.2-.158.347-.158.347 1.443.174 1.095-1.44 1.095-1.44" />{' '}
          <path d="M75.564 84.22c.457-8.617 1.145-14.722 1.145-15.484 0-.27-.03-.62-.144-1.005l-.5 1.64-.155-2.897c-.252-.324-.594-.648-1.03-.96h-.027c-.432.31-.736.635-1 .96l-.15 2.897-.505-1.642c-.112.386-.144.737-.144 1.007 0 .763.686 6.868 1.143 15.484s-.6 10.222-.6 10.222c.62.895 1.3 2.49 1.3 2.49s.67-1.594 1.3-2.49c0 0-1.07-1.603-.613-10.222" />{' '}
        </g>
      </g>
    </svg>
  );
};

NOP.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

NOP.defaultProps = {
  size: '100',
};

export default NOP;
