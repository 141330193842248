import React from 'react';
import PropTypes from 'prop-types';

const HOU = props => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height={size}
      viewBox="0 0 150 150"
      fill="none"
      fillRule="evenodd"
      role="img"
      aria-describedby='title'
    >
      <title id="title">Houston Rockets</title>
      <path
        d="M39.07 79.37c2.857-1.547 10.52-2.748 17.586-3.8l-.137 8.665C44.033 82.856 39.503 80.44 39.07 79.37zm30.3-41c7.432 1.003 11.684 2.972 12.037 5.603.365 2.718-.272 4.948-1.85 6.708-2.552 2.323-6.434 3.54-9.724 4.176.036-6.316-.114-11.638-.463-16.487zm.212 29.423l.182-7.734c6.075.895 9.607 2.574 9.547 4.6l-.19 20.43a188.57 188.57 0 0 1-9.739-.021l.045-1.377c-.137-4.622.006-10.102.155-15.906zm22.5 16.538l.102-8.668c13.833 1.883 17.323 3.37 17.756 4.2 0 0-.012.063-.1.2-2.495 1.814-9.036 3.38-17.76 4.268zm31.054-3.885c-.057-1.2-.648-2.323-1.76-3.34-4.17-3.804-16.017-6.058-29.14-5.68l.114-9.644c-.003-2.002-.66-3.658-1.948-4.92-.905-.886-2.092-1.55-3.55-2 1.366-.817 2.704-1.826 3.84-3.062 3.212-2.98 4.69-6.738 4.393-11.162-.19-2.805-1.4-5.18-3.628-7.06-4.387-3.73-12.353-5.187-23.063-4.232-.813-5.642-1.954-10.533-3.48-14.92l-1.464-4.205-.505 1.562-.275-.793-1.37 4.244c-1.9 5.912-3.165 10.9-3.94 15.63-8.8 1.586-14.837 3.55-15.1 3.634l-12.55 4.1 2.193-.2-2.943.964 13.124-1.188c.072-.01 6.724-.54 14.46-.572-.433 5.624-.296 11.3-.123 18.307l.24 15.526c-11.854-.156-24.907 1.628-28.732 6.11-1.655 1.407-1.966 2.83-1.933 3.783.26 7.432 22.074 10.2 30.716 11.018l.717 22.95 2.982.066.06-.793.203.006.146 5.828 2.985.075.06-.793.338.01.15 5.528 2.988.01.027-.775h.744l1.016-31.396a177.81 177.81 0 0 0 10.029-.012l1.034 32.794 2.985.03.04-.78.37 7.836 2.985.01.036-.778.296.003.21 7.824 2.988-.006.018-.775h.76l1.094-46.92c15.357-1.46 26.273-4.568 29.138-8.177 1.348-1.137 2.032-2.35 2.032-3.604v-.07z"
        fill="#fefefe"
      />
      <g fill="#2d2925">
        <path d="M120.88 81.278c0 5.463-21.26 10.354-46.92 10.354s-46.273-4.894-46.464-10.354c-.173-4.924 14.358-8.15 31.015-7.513-10.82 1.628-21.745 3.167-21.745 6.097 0 4.424 16.405 7.546 37.195 7.546 20.783 0 37.484-3.122 37.484-7.546 0-2.856-10.023-4.556-22.07-6.094 16.863-.898 31.293 2.76 31.508 7.5" />
        <path d="M65.24 102.593l.616 22.6 1.315-40.675c-.53-17.873 3.096-47.206-4.438-68.82-7.997 24.847-3.693 30.438-4.745 69.482l.94 30.082 1.252-16.454c.042-2.04 1.593-1.853 1.593-.102l.55 21.668 1.333-17.633c-.02-2.377 1.647-2.27 1.584-.138" />
        <path d="M89.756 91.027l-1.144 48.918-.77-28.807c-.042-2.044-1.748-2.137-1.817 0l-.938 21.734-.923-25.16c.003-2.023-1.542-2.14-1.557.144l-.917 17.94-1.115-35.43.242-25.694c.203-6.87-19.964-6.783-19.964-6.783s24.013.5 22.047-14.125c-1.43-10.662-40.942-7.076-40.942-7.076s11.17-3.66 25.18-5.023c15.703-1.524 25.176 2.257 25.7 9.842.905 13.422-16.447 15.376-16.447 15.376s13.692-2.66 13.716 5.663l-.338 28.48z" />
      </g>
      <g fill="#c21f32">
        <path d="M121.653 80.504c0 5.463-21.258 10.357-46.918 10.357-25.666 0-46.276-4.897-46.464-10.357-.176-4.92 14.358-8.154 31.012-7.513-10.82 1.63-21.745 3.17-21.745 6.097 0 4.427 16.408 7.552 37.198 7.552 20.783 0 37.48-3.125 37.48-7.552 0-2.853-10.02-4.553-22.07-6.097 16.86-.898 31.3 2.76 31.508 7.513" />
        <path d="M66.014 101.82l.613 22.6 1.315-40.675c-.53-17.876 3.096-47.206-4.432-68.82-8.002 24.847-3.7 30.438-4.748 69.48l.94 30.082 1.252-16.454c.04-2.038 1.6-1.853 1.6-.096l.556 21.665 1.33-17.633c-.02-2.38 1.64-2.27 1.584-.138" />
        <path d="M90.53 90.252l-1.142 48.918-.774-28.807c-.04-2.044-1.745-2.14-1.817 0l-.938 21.73-.923-25.158c.003-2.023-1.542-2.143-1.554.144l-.92 17.94-1.115-35.43.242-25.694c.203-6.872-19.96-6.777-19.96-6.777s24 .497 22.044-14.13c-1.434-10.662-40.94-7.073-40.94-7.073s11.17-3.667 25.176-5.026c15.706-1.527 25.18 2.254 25.693 9.845.902 13.42-16.447 15.376-16.447 15.376s13.69-2.667 13.713 5.66l-.338 28.48z" />
      </g>
    </svg>
  );
};

HOU.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

HOU.defaultProps = {
  size: '100'
};

export default HOU;
