import ATL from '../icons/nba/atl'
import BKN from '../icons/nba/bkn';
import BOS from '../icons/nba/bos';
import CHA from '../icons/nba/cha';
import CHI from '../icons/nba/chi';
import CLE from '../icons/nba/cle';
import DAL from '../icons/nba/dal';
import DEN from '../icons/nba/den';
import DET from '../icons/nba/det';
import GSW from '../icons/nba/gsw';
import HOU from '../icons/nba/hou';
import IND from '../icons/nba/ind';
import LAC from '../icons/nba/lac';
import LAL from '../icons/nba/lal';
import MEM from '../icons/nba/mem';
import MIA from '../icons/nba/mia';
import MIL from '../icons/nba/mil';
import MIN from '../icons/nba/min';
import NOP from '../icons/nba/nop';
import NYK from '../icons/nba/nyk';
import OKC from '../icons/nba/okc';
import ORL from '../icons/nba/orl';
import PHI from '../icons/nba/phi';
import PHX from '../icons/nba/phx';
import POR from '../icons/nba/por';
import SAC from '../icons/nba/sac';
import SAS from '../icons/nba/sas';
import TOR from '../icons/nba/tor';
import UTA from '../icons/nba/uta';
import WAS from '../icons/nba/was';

import React from 'react';

// this is a boot strap of https://github.com/ChrisKatsaras/React-NBA-Logos since the module is not compatible with our version of node
function NBALogo(props) {//extends React.Component {
    //render() {
    switch (props.team) {
        case 'ATL': return <ATL size={props.size || 40} />;
        case 'BKN': return <BKN size={props.size || 40} />;
        case 'BOS': return <BOS size={props.size || 40} />;
        case 'CHA': return <CHA size={props.size || 40} />;
        case 'CHI': return <CHI size={props.size || 40} />;
        case 'CLE': return <CLE size={props.size || 40} />;
        case 'DAL': return <DAL size={props.size || 40} />;
        case 'DEN': return <DEN size={props.size || 40} />;
        case 'DET': return <DET size={props.size || 40} />;
        case 'GSW': return <GSW size={props.size || 40} />;
        case 'HOU': return <HOU size={props.size || 40} />;
        case 'IND': return <IND size={props.size || 40} />;
        case 'LAC': return <LAC size={props.size || 40} />;
        case 'LAL': return <LAL size={props.size || 40} />;
        case 'MEM': return <MEM size={props.size || 40} />;
        case 'MIA': return <MIA size={props.size || 40} />;
        case 'MIL': return <MIL size={props.size || 40} />;
        case 'MIN': return <MIN size={props.size || 40} />;
        case 'NOP': return <NOP size={props.size || 40} />;
        case 'NYK': return <NYK size={props.size || 40} />;
        case 'OKC': return <OKC size={props.size || 40} />;
        case 'ORL': return <ORL size={props.size || 40} />;
        case 'PHI': return <PHI size={props.size || 40} />;
        case 'PHX': return <PHX size={props.size || 40} />;
        case 'POR': return <POR size={props.size || 40} />;
        case 'SAC': return <SAC size={props.size || 40} />;
        case 'SAS': return <SAS size={props.size || 40} />;
        case 'TOR': return <TOR size={props.size || 40} />;
        case 'UTA': return <UTA size={props.size || 40} />;
        case 'WAS': return <WAS size={props.size || 40} />;
        default: return <ATL />;
        //      }
    }
}

export default NBALogo